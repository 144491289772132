<template>
  <div class="abstract-data-table__td">
    <span v-if="header" class="abstract-data-table__td-desc">{{ header }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AbstractColumn',
  props: {
    header: {
      type: String,
      required: true
    }
  }
}
</script>
