<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="board"
    :total-count="totalCount"
    :button-required-permissions="requiredPermissions"
    :show-pagination="false"
    store-name="hottopics"
    table-css-class="hottopics-table"
    :show-detail-button="() => false"
    :show-edit-button="() => isActivePanels"
    store-action-reload-name="fetchBoard"
    :store-action-reload-param="siteId"
    @updateOrder="handleUpdateOrder"
  >
    <template #tableBody="{ item }">
      <AbstractColumn :header="tableHeaders[0].name">
        <button
          v-if="item.isPublished"
          class="abstract-data-table__drag-button"
          type="button"
          :aria-label="$t('hottopics.drag_label')"
        >
          <svg
            class="abstract-data-table__drag-button-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            role="img"
            aria-hidden="true"
            focusable="false">
            <path d="M5 4h2V2H5v2zm6-2v2h2V2h-2zm-6 8h2V8H5v2zm6 0h2V8h-2v2zm-6 6h2v-2H5v2zm6 0h2v-2h-2v2z"></path>
          </svg>
        </button>
      </AbstractColumn>
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.id"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.text"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="item.unpublishedAt ? '-' : item.position"
      />
      <TitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="item.publishedAt ? $options.filters.prettyDateTime(item.publishedAt) : '-'"
      />
      <TitleColumn
        :title-header="tableHeaders[5].name"
        :title-value="item.unpublishedAt ? $options.filters.prettyDateTime(item.unpublishedAt) : '-'"
      />
      <AbstractColumn :header="tableHeaders[6].name">
        <span v-if="item.isPublished" class="label label-success">
          {{ $t('hottopics.state_active') }}
        </span>
        <span v-else class="label label-warning">
          {{ $t('hottopics.state_inactive') }}
        </span>
      </AbstractColumn>
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import AbstractColumn from '@/components/table/columns/AbstractColumn'
import TitleColumn from '@/components/table/columns/TitleColumn'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'HotTopicsTable',
  props: {
    board: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    isActivePanels: {
      type: Boolean,
      default: false
    },
    storeActionReloadParam: {
      type: [Number, String],
      default: ''
    },
    siteId: {
      type: Number,
      required: true
    }
  },
  components: {
    AbstractDataTable,
    AbstractColumn,
    TitleColumn
  },
  data () {
    return {
      tableHeaders: [
        { name: '' },
        { name: this.$t('hottopics.list.id') },
        { name: this.$t('hottopics.list.text') },
        { name: this.$t('hottopics.list.position') },
        { name: this.$t('hottopics.list.from') },
        { name: this.$t('hottopics.list.to') },
        { name: this.$t('hottopics.list.state') },
        { name: this.$t('actions') }
      ],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.HOT_TOPICS_PERMISSIONS
    }
  },
  methods: {
    async handleUpdateOrder (sortedItems) {
      for (let index = 0; index < sortedItems.length; index++) {
        const item = sortedItems[index]
        const newPosition = index + 1
        if (item.position !== newPosition) {
          try {
            await this.$store.dispatch('hottopics/update', {
              id: item.id,
              position: newPosition,
              text: item.text,
              imageId: null,
              linkUrl: item.linkUrl
            })
          } catch (error) {
            console.error(`Failed to update position for item ID: ${item.id}`, error)
          }
        }
      }
      this.$store.dispatch('hottopics/fetchBoard', this.siteId)
    }
  }
}
</script>
<style lang="scss">
  .hottopics-table {
    border: rem(1px) solid  rgba(0,0,0,0.125);
    box-shadow: 0rem rem(5px) rem(20px) rgba(0,0,0,0.05);

    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(40px) rem(50px) auto rem(50px) rem(130px) rem(130px) rem(100px) rem(80px);
      }
    }
  }
</style>
