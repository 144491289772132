<template>
  <section class="hottopics-filter page-titles row">
    <div class="col">
      <Select
        v-model="currentSiteId"
        :options="sites"
        :label="$t('hottopics.filter.select_site_label')"
        no-empty-value
        disable-form-group
        :compact-width="true"
      />
    </div>
    <div class="col-md-auto d-flex align-items-md-end">
      <SwitchCheckbox
        v-model="graphicsTextSwitch"
        id="hottopics_graphicsTextSwitch"
        :true-label="$t('hottopics.switch.text_label')"
        :false-label="$t('hottopics.switch.graphics_label')"
        class="hottopics-filter__switch-checkbox"
      />
      <ButtonCreate
        route-name="hottopics_new"
        :label="$t('hottopics.create_panel_label')"
      />
    </div>
  </section>
</template>

<script>
import { MODULE_MODULES_HOT_TOPICS } from '@/model/ValueObject/UserPermissionModules'
import PermissionService from '@/services/PermissionService'
import Select from '@/components/form/select/Select'
import ButtonCreate from '@/components/shared/ButtonCreate'
import SwitchCheckbox from '@/components/form/SwitchCheckbox'

export default {
  name: 'HotTopicsFilter',
  components: {
    Select,
    ButtonCreate,
    SwitchCheckbox
  },
  data () {
    return {
      isLoading: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.HOT_TOPICS_PERMISSIONS,
      currentSiteId: this.$store.state.hottopics.siteId || null
    }
  },
  watch: {
    currentSiteId (newSiteId, oldSiteId) {
      this.handleSiteChange(newSiteId)
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_MODULES_HOT_TOPICS)
    },
    graphicsTextSwitch: {
      get () {
        return this.$store.getters['hottopics/board'].textOnly || false
      },
      set (value) {
        this.handleGraphicsTextSwitch(value)
      }
    }
  },
  methods: {
    handleSiteChange (siteId) {
      this.$store.commit('hottopics/setSiteId', siteId)
    },
    handleGraphicsTextSwitch (value) {
      this.$store.dispatch('hottopics/updateGraphicsTextSwitch', {
        siteId: this.currentSiteId,
        textOnly: value
      })
    }
  },
  created () {
    if (!this.currentSiteId && this.sites.length) {
      this.currentSiteId = this.sites[0].id
    }
  }
}
</script>

<style lang="scss">
  .hottopics-filter {
    display: flex;
    background: transparent;
    padding: rem(10px) rem(5px);
    margin: 0 rem(-20px) rem(20px);
    &__expand {
      flex: 1;
    }
    &__shrink {
      flex: 0;
    }
    &__switch-checkbox {
      margin-bottom: rem(3px);
      margin-right: rem(25px);
    }
  }
  .filter-panel-hottopics {
    @include bp(7) {
      grid-template-columns: 1fr 1fr;
    }
    @include bp(12) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
</style>
