<template>
  <div>
    <div>
      {{ label }}
    </div>
    <div class="switch-container">
      <div v-if="important" class="important">{{ `!` }}</div>
      {{ trueLabel }}
      <label class="switch">
        <input
          type="checkbox"
          :id="id"
          v-model="localValue"
          :disabled="disabled"
          :data-test="dataAttr ? dataAttr : id"
        />
        <span :class="{ 'switch__slider': true, 'switch__slider--disabled': disabled }" />
      </label>
      {{ falseLabel }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchCheckbox',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    falseLabel: {
      type: String,
      default: ''
    },
    trueLabel: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    important: {
      type: Boolean,
      default: false
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        if (!this.disabled) {
          this.$emit('input', val)
        }
      }
    }
  },
  methods: {
    checked () {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.switch-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}
.switch {
  position: relative;
  display: inline-block;
  width: rem(60px);
  height: rem(34px);

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: rem(34px);
    &--disabled {
      cursor: default;
    }
    &:before {
      position: absolute;
      content: "";
      height: rem(26px);
      width: rem(26px);
      left: rem(30px);
      bottom: rem(4px);
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .switch__slider {
  background-color: #4caf50;
}

input:checked + .switch__slider:before {
  -webkit-transform: translateX(-26px);
  -ms-transform: translateX(-26px);
  transform: translateX(-26px);
}

.important {
  width: rem(24px);
  height: rem(24px);
  border-radius: 50%;
  background-color: #b70b0b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: rem(18px);
  font-weight: bold;
}
</style>
