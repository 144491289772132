<template>
  <section>
    <HotTopicsFilter />
    <Preloader v-if="callingAPI" />
    <h3 class="hottopics-list__title">{{ $t('hottopics.active_panels') }} ({{ activeBoardItemsCount }})</h3>
    <HotTopicsTable
      v-if="activeBoardItems && activeBoardItems.length"
      :board="activeBoardItems"
      :is-active-panels="true"
      :site-id="siteId"
    />

    <h3 class="hottopics-list__title mt-4">{{ $t('hottopics.inactive_panels') }}</h3>
    <HotTopicsTable
      v-if="inactiveBoardItems && inactiveBoardItems.length"
      :board="inactiveBoardItems"
      :site-id="siteId"
    />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import HotTopicsFilter from '@/components/filter/FilterHotTopics'
import HotTopicsTable from '@/components/table/HotTopicsTable'

export default {
  name: 'HotTopicsListView',
  components: {
    Preloader,
    HotTopicsFilter,
    HotTopicsTable
  },
  data () {
    return {
      dataLoaded: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('hottopics', ['siteId']),
    board () {
      return this.$store.getters['hottopics/board']
    },
    activeBoardItems () {
      return this.board?.hotTopics?.filter(item => item.isPublished)
        .sort((a, b) => a.position - b.position) || []
    },
    inactiveBoardItems () {
      return this.board?.hotTopics?.filter(item => !item.isPublished)
    },
    activeBoardItemsCount () {
      return this.activeBoardItems.length
    }
  },
  watch: {
    siteId: {
      immediate: true,
      handler (newSiteId) {
        if (newSiteId) {
          this.getBoard(newSiteId)
        }
      }
    }
  },
  methods: {
    getBoard (siteId) {
      this.$store.dispatch('hottopics/fetchBoard', siteId)
        .then(() => {
          this.dataLoaded = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
.hottopics-list {
  &__title {
    color: #8A96AC;
  }
}
</style>
